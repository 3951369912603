/* prettier-ignore */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import 'variables';
@import 'mixins';
@import 'utils';
@import 'grid';
@import 'blocks';
@import 'typography';
@import 'ui-view';
@import 'navigation';
@import 'inputs';
@import 'icons';
@import 'buttons_and_links';
@import 'dashboard';
@import 'campaigns';
@import 'fonts';
@import 'inventory';
@import 'loaders';
@import 'agency_overview';
@import 'dialogs';
@import 'tables';
@import 'ngx_slider_override';
@import 'ui-select';

@-ms-viewport {
  width: device-width;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.clearfix:after,
.clearfix:before {
  display: table;
  content: ' ';
}

html,
body {
  //font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: 'quattro_news', sans-serif;
  font-size: 16px;
  line-height: 1.4;
  height: 100%;
  background-color: $light-gray--primary;
  color: $dark-gray--primary;
}

input,
button {
  outline: none; // remove all chrome-type blocks around inputs.
}

// Removes marking around focused elements in firefox
:-moz-focusring {
  outline: none;
}

ul {
  margin: 0;
}

.ul__woo,
.ul--woo {
  margin: 0;
  position: relative;
  padding-left: 0;
  li {
    @extend .animate--fast;
    list-style: none;
    padding-left: 16px;
    position: relative;
    margin: 6px 0;
    &:before {
      @extend .animate--fast;
      position: absolute;
      top: -2px;
      left: 0;
      font-family: 'tv4-icon-font';
      content: '\e979';
      font-size: 16px;
      line-height: 26px;
      width: 10px;
      color: $tv4-red;
    }
  }
  .ul--black {
    li:before {
      color: $dark-gray--primary;
    }
  }

  ul {
    padding-left: 16px;
    li {
      &:before {
        color: $tv4-red;
      }
    }
  }

  &.ul--small {
    font-size: 14px;
    li {
      font-size: 14px;
      margin: 2px 0;

      &:before {
        line-height: 22px;
      }
    }
  }

  &.ul--hover {
    li {
      &:hover {
        &:before {
          transform: translateX(-10px);
        }
      }
    }
  }
}

img,
video {
  width: 100%;
}

.container {
  width: 100%;
  padding: 0;
  padding-left: 5%;
}

.site {
  .navigation {
    width: 320px;
    height: 100%;
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 8500;
    transition: all 600ms ease-out;
  }

  .navbar {
    transition: all 600ms ease-out;
  }

  .site-wrapper {
    background-color: $light-gray--primary;
    width: 100%;
    height: 100%;
    padding: 100px 0px 0 0px;
    transition: all 600ms ease-out;
    box-sizing: border-box;
  }

  &.nav-open {
    overflow: hidden;

    .navigation {
      -webkit-transform: translate3d(320px, 0, 0);
      transform: translate3d(320px, 0, 0);

      .nav-item {
        opacity: 1;
      }

      .nav-item--login {
        opacity: 1;
        padding: 0 $spacing-2x;
      }
    }

    .navbar {
      -webkit-transform: translate3d(320px, 0, 0);
      transform: translate3d(320px, 0, 0);
    }

    .site-wrapper {
      -webkit-transform: translate3d(320px, 0, 0);
      transform: translate3d(320px, 0, 0);
      position: absolute;
      z-index: 2;
    }
  }
}

.area {
  padding: $spacing-2x;
}

/* Utility */

.divider {
  height: 1px;
  background-color: $mid-gray--lightest;
  width: 100%;

  &.medium {
    height: 2px;
  }

  &.strong {
    height: 3px;
  }

  &.dark {
    background-color: $darker-gray;
  }

  &.info {
    background-color: $dark-gray;
  }
}

/* Inputs */

.tab {
  background-color: $dark-gray;
  padding: $spacing-1x;
  font-weight: $medium;
  margin: 4px 0 4px 4px;
  padding: $spacing-1x $spacing-2x;
  display: inline-block;
  color: $full-white;

  &:first-child {
    margin-left: 0px;
  }

  &.info {
    background-color: $full-white;
    color: $dark-gray--primary;
  }

  &.success {
    background-color: $positive-feedback--green;
    color: $full-white;
  }

  &.warning {
    background-color: $warning-yellow;
    color: $full-white;
  }

  &.action {
    background-color: $tv4-red;
    color: $full-white;
  }

  &.rounded {
    border-radius: 4px;
  }

  &.tab--small {
    padding: 4px 8px;
    margin: 2px 4px;
    border-radius: 3px;
    font-size: 14px;
  }

  &.disabled {
    background-color: $grey-disabled;
    color: $brown;
  }
}

.status-badge,
.status__badge {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: $dark-gray;
  border-radius: 50%;
  margin: $spacing-2x auto;
  transition: all 500ms ease-out;
  color: $full-white;
  font-size: 24px;
  line-height: 44px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    font-family: 'tv4-icon-font';
    color: $full-white;
    display: block;
    line-height: 40px;
    width: 38px;
    height: 40px;
  }

  &.large_icon {
    font-size: 32px;
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(143, 134, 130, 0.5);
    transition: none;
  }

  &.success {
    background-color: $positive-feedback--green;
  }

  &.green-success {
    background-color: $green;
  }

  &.danger,
  &.error {
    background-color: $error-red;
  }

  &.warning {
    background-color: $warning-yellow;
  }

  &.active {
    background-color: $tv4-red;
  }

  &.info {
    background-color: $mid-gray--darkest;
  }

  &.inverse {
    background-color: $full-white;
    color: $tv4-red;
  }

  &.dark {
    background-color: $brown;
  }

  &.large {
    width: 60px;
    height: 60px;
  }

  &.cta {
    background-color: $warning-yellow;
  }

  &.primary {
    background-color: $tv4-red;
    color: $full-white;
  }

  &.small {
    width: 20px;
    height: 20px;
    margin: 0;
    font-size: 12px;
    line-height: 22px;

    &.large_icon {
      font-size: 16px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
    }

    &.center {
      margin: 2px auto;
    }
  }

  &.medium {
    width: 30px;
    height: 30px;
    margin: 0;
    font-size: 16px;
    line-height: 22px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
    }

    &.center {
      margin: 2px auto;
    }
  }

  &.with--icon,
  &.with-icon {
    &.success {
      &::before {
        text-align: center;
        content: '\e96c';
      }
    }

    &.cta {
      &::before {
        text-align: center;
        content: '\e9a1';
      }
    }

    &.danger,
    &.error {
      &::before {
        text-align: center;
        content: '\e9a2';
      }
    }
  }

  &.right--padding {
    padding-right: $spacing-1x;
  }
}

.badge--focus:focus {
  box-shadow: 0px 0px 0px 5px rgba($brown, 0.5);
}

.status__badge,
.status-badge,
.hover__container,
.hover-container {
  .hover-text,
  .hover__text {
    transition: all 300ms ease-in-out;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -30px;
    background: $full-white;
    color: $tv4-red;
    padding: 6px;
    font-size: 16px;
    margin: 0;
    line-height: 1;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
    opacity: 0;
    z-index: 100;
    white-space: nowrap;
    pointer-events: none;
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: $highlighted-gray;
      border-width: 6px;
      margin-left: -6px;
    }
    &.hover--lower {
      -webkit-transform: translateX(-50%) translateY(50%);
      transform: translateX(-50%) translateY(50%);
    }
  }

  &:hover {
    .hover__text,
    .hover-text {
      opacity: 1;
      top: -38px;
    }
    &.hover__info {
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.12);
      transition: all 400ms ease-in-out;
      transform: scale(1.2, 1.2);
    }
  }
}

.hover-container,
.hover__container {
  position: relative;

  .hover__text,
  .hover-text {
    font-size: 14px;
    line-height: 1.2;
  }

  &:hover {
    .hover__text,
    .hover-text {
      top: -50px;
    }
  }
}

.pointer {
  cursor: pointer;
}

.word--break {
  word-break: break-all;
}

.no-wrap {
  white-space: nowrap;
}

.animate-show {
  opacity: 1;
  visibility: visible;
  &.ng-hide-add.ng-hide-add-active,
  &.ng-hide-remove.ng-hide-remove-active {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &.ng-hide {
    opacity: 0;
    visibility: none;
  }
}

.account__card {
  @extend .animate;
  @extend .shadow--card-bottom-border;
  position: relative;
  background-color: $full-white;
  padding: $spacing-4x;
  margin: 0;
  margin-bottom: $spacing-4x;

  &.no--padding {
    padding: 0;
  }

  &.no-margin-top-headings {
    h2 {
      margin-top: 0;
    }
  }

  &.no--sidepadding {
    padding-right: 4px;
    padding-left: 4px;
  }

  &.account__card--small,
  &.small {
    padding: $spacing-2x;
    margin-bottom: $spacing-1x;
    margin-top: $spacing-1x;
  }

  &.account__card--margin--right {
    margin-right: $spacing-1x;
  }

  &.no--margin {
    margin-bottom: 0;
  }

  .card__info {
    margin: 4px 0;
  }
  .address__info {
    @extend .shadow-z-1;
    padding: $spacing-2x;
    background-color: $highlighted-gray;
    margin-bottom: $spacing-2x;
    margin-left: -$spacing-2x;
    border-radius: 4px;
  }

  &.away {
    transform: translateX(100px);
    opacity: 0;
    .card__cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: $full-white;
    }
  }

  &.account__card--no--animate {
    transition: none;
  }

  .card_cover {
    display: none;
  }

  .close__button {
    position: absolute;
    top: 0;
    right: $spacing-4x;
  }
}

.flip--horizontal {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

ul {
  &.reset--list {
    margin: 0;
    padding: 0;

    li {
      list-style-position: inside;
      list-style-type: none;
    }
  }
  &.inline--list {
    li {
      display: inline;
    }
  }
}

.toggable__tab {
  @extend .animate;
  display: block;
  max-height: 100%;

  &.closed {
    //Default h3 + divider
    max-height: 73px;
    overflow: hidden;
  }

  .toggler {
    cursor: pointer;
  }
}

/* 758 - 1 */
@include phone {
  .site {
    .site-wrapper {
      padding-top: 50px;
      &.nav-open {
        display: none;
      }
    }
  }
}
/* 758 - 1 */
@include tablet {
  .site {
    .site-wrapper {
      padding-top: 50px;
      &.nav-open {
        display: none;
      }
    }
  }
}

.legend {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  margin-right: 8px;
}

.chevron {
  // width: 33px;
  // height: 33px;
  &::before {
    @extend .animate--fast;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
    left: 0px;
    position: relative;
    top: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    vertical-align: top;
    margin: $spacing-1x;
  }
  &.bottom {
    &::before {
      top: -2px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  &.up {
    &::before {
      top: -2px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }

  &.medium {
    &::before {
      height: 15px;
      width: 15px;
      border-width: 3px 3px 0 0;
      top: 2px;
    }
    &.bottom,
    &.up {
      &::before {
        top: 0px;
      }
    }
  }

  &.large {
    &::before {
      height: 20px;
      width: 20px;
      border-width: 4px 4px 0 0;
      top: 10px;
    }
    &.bottom,
    &.up {
      &::before {
        top: 0px;
      }
    }
  }
}

thead.bold {
  tr {
    font-weight: bold;
  }
}

.rotate__plus {
  width: 45px;
  transition: all 400ms ease-in-out;
  -webkit-transform: rotate(405deg);
  transform: rotate(405deg);
}

.give-me-some-400px-min-height {
  min-height: 400px;
}

.max-width--350px {
  max-width: 350px;
  word-break: break-all;
}

.tooltip {
  .tooltip-inner {
    background-color: $full-white;
    color: $tv4-red;
    font-size: 16px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
    z-index: 1000;
  }
  .tooltip-arrow {
    border-bottom-color: $full-white !important;
  }
}
