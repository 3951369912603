.nav-list {
  color: $full-white;
  padding-left: 0;
  li {
    display: block !important;
    float: left;
    height: 100%;
    color: $full-white;
    a {
      font-size: 24px;
    }
  }
  .header-text {
    margin: 4px 0 0 10px;
  }
}

.navbar-logo {
  display: block;
  width: 40px;
  height: 40px;
  background: url(../images/logos/tv4.svg);
}

.toggle-navigation {
  background-color: transparent;
  border: 0;
  color: $dark-gray--primary;
  font-size: 18px;
  width: 24px;
  outline: 0;
  padding: 0;
  margin-right: $spacing-2x;
  margin-top: 5px;

  .icon-bar {
    box-sizing: content-box;
    height: 2px;
    background-color: $dark-gray--primary;
    width: 100%;
    margin: 6px 0;
    position: relative;
    display: block;
    border-radius: 2px;
    transition: all 400ms ease-out;

    &.open {
      height: 1px;
      &.top {
        margin-top: 16px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.middle {
        opacity: 0;
      }

      &.bottom {
        margin-top: -14px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}

.navbar {
  background-color: $full-white;
  color: $dark-gray--primary;
  border: 0;
  margin: 0;
  font-size: 24px;
  padding: 33px 0;
  font-weight: $light;
  transition: all 400ms ease-in-out;
  height: $nav-bar-height--default;
  .top-navbar {
    min-height: 50px;
  }

  li {
    a {
      color: $dark-red-secondary;
    }
  }

  .nav-list {
    li {
      display: block !important;
      float: left;
      height: 100%;

      .links {
        margin-top: 4px;
        text-transform: uppercase;
        a {
          margin-left: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .brand {
    font-weight: $light;
    margin: 0;
    line-height: 1;
    .brand-slogan {
      font-size: 10px;
      opacity: 0.5;
      display: block;
    }
  }

  &.slide {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.navigation {
  position: relative;
  background-color: $full-white;
  padding-top: 100px;
  color: $dark-gray--primary;
  .nav-item {
    font-size: 18px;
    font-weight: 300;
    border-top: 1px solid $mid-gray--lightest;
    a {
      display: block;
      padding: $spacing-2x;
      padding-left: $spacing-4x;
      color: $dark-gray--primary;
      font-size: 18px;
      font-weight: $light;

      &:hover,
      &:focus {
        background-color: $pink--pale;
        color: $tv4-red;
        outline: none;
      }
      &.active {
        text-decoration: underline;
      }
    }
    &.active {
      border-top: 1px solid $mid-gray--darkest;
      background-color: $mid-gray--lighter;
    }
    &.active + div {
      border-top: 1px solid $mid-gray--darkest;
    }
    &:last-child {
      border-bottom: 1px solid $dark-gray;
    }
    &:hover {
      //border-top: 1px solid $mid-red-secondary;
      background-color: $pink--pale;
    }
    &:hover + div {
      //border-top: 1px solid $mid-red-secondary;
    }
  }
}

.site {
  &.home {
    .navbar {
      -webkit-transform: translate3d(0, 0%, 0);
      transform: translate3d(0, 0%, 0);

      &.slide {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
      }
    }

    &.nav-open {
      .navbar {
        -webkit-transform: translate3d(320px, 0%, 0);
        transform: translate3d(320px, 0%, 0);

        &.slide {
          -webkit-transform: translate3d(320px, -100%, 0);
          transform: translate3d(320px, -100%, 0);
        }
      }
    }
  }
}

/* 1199 - 1024 */
@include desktop {
  .navbar {
    width: 100%;
    margin: 0;
    .button {
      padding: 8px 16px;
    }
  }
}

/* 1024-768 */
@include tablet {
  .navigation {
    padding-top: 50px;
  }
  .navbar {
    width: 100%;
    margin: 0;
    height: $nav-bar-height--tablet;
    padding: $spacing-1x 0;
    .links {
      font-size: 18px;
      a {
        margin-left: 20px !important;
      }
    }
    .button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}

/* 758 - 1 */
@include phone {
  .navigation {
    padding-top: 50px;
  }
  .navbar {
    width: 100%;
    margin: 0;
    height: $nav-bar-height--phone;
    padding: $spacing-1x 0;

    .header {
      .logo {
        max-height: 28px;
      }
    }
    .button {
      padding: 6px 12px;
      font-size: 12px;
    }
  }
}
